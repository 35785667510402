export const status_codes = {
  init: { code: 100, message: 'Continue' },
  ok: { code: 200, message: 'OK' },
  accepted: { code: 202, message: 'Accepted' },
  no_content: { code: 204, message: 'No Content' },
  bad_request: { code: 400, message: 'Bad Request' },
  unauthorized: { code: 401, message: 'Unauthorized' },
  not_found: { code: 404, message: 'Not Found' },
  request_timeout: { code: 408, message: 'Request Timeout' },
  internal_server_error: { code: 500, message: 'Internal Server Error' },
  service_unavailable: { code: 503, message: 'Service Unavailable' }
};


export default status_codes;