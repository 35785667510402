import $ from 'jquery';
import status_codes from '../util/status_codes';

export const header_menu = new (function(){
  let self = this

  self.init = function(menu, toggle, has_sub, has_sub_class, 
  parent, active, sub_active, sub, item){
    self.prop.menu = menu
    self.prop.toggle = toggle
    self.prop.has_sub = has_sub
    self.prop.has_sub_class = has_sub_class
    self.prop.parent = parent
    self.prop.active = active
    self.prop.sub_active = sub_active
    self.prop.sub = sub
    self.prop.item = item
    self.domEvents(self.error.log) 
    self.emitter.emit('init', status_codes.ok)
    //self.listener.list()
  }


  self.prop = {
    'menu': '',
    'toggle':'',
    'has_sub':'',
    'has_sub_class': '',
    'parent': '',
    'active': '',
    'sub_active': '',
    'sub': '',
    'item':''
  }

  self.emitter = {
    'events': {},
    'on': function(eventName, listener) {
      if (!self.emitter.events[eventName]) {
        self.emitter.events[eventName] = [];
      }
      self.emitter.events[eventName].push(listener);
    },
    'emit': function(eventName, ...args) {
      if(self.emitter.events[eventName]) {
        self.emitter.events[eventName].forEach(listener => {
          listener.apply(null, args);
        });
      }
    }
  }

  self.listener = {
    'list': function(){
      otherObj.emitter.on('init', function(data) {

      })
    }
  }

  self.domEvents = function(error){
    self.event = {
      'window': $(window).on('resize', function(){
        if ($(window).width() > 700) {
          $('.menu-item').css('display', '')
        }
      }),
      'document': $(document).on('click', function(event){
        self.method.close(event)
      }),
      'toggle': $(self.prop.toggle).on('click', function(){
        self.method.toggle()
      }),
      'item': $('.has-menu-submenu').on('click', function(){
        self.method.submenu(this)
      })
    }
    //self.callback.exec(error,  ['this is an error message', '>', 'additional argument'])
  }

  self.method = {
    'close': function(event){
      var $target = $(event.target).parent()
      if(!$target.hasClass('has-menu-submenu')){
        $('.has-menu-submenu').find('.menu-submenu').slideUp(300).promise().done(function() {
          $('.has-menu-submenu').removeClass('menu-submenu-active submenu-parent');
        });
      }
    },
    'toggle': function(){
      $(self.prop.menu).find('.menu-item').slideToggle(300, function() {
        $(self.prop.menu).toggleClass('active')
        var icon
        if($(self.prop.menu).hasClass('active')){
            icon = '<i class="fa fa-times"></i>'
        }
        else {
            icon = '<i class="fa fa-bars"></i>'
        }
        $(self.prop.toggle).find('a').html(icon)
      });
    },
    'submenu': function(el){
      var $current = $(el)
      var $last = $('.menu-submenu-active').not($current)
      $last.removeClass('submenu-parent').find('.menu-submenu').slideUp(300, function() {
          $(this).parent().removeClass('menu-submenu-active')
      })
      if($(el).hasClass('menu-submenu-active')){
        $(el).removeClass('submenu-parent').find('.menu-submenu').slideUp(300, function() {
          $(el).toggleClass('menu-submenu-active')
        })
      }
      else {
        $(el).addClass('submenu-parent').find('.menu-submenu').slideDown(300, function() {
          $(el).addClass('menu-submenu-active')
        })
      }        

    }
  }

  self.callback = {
    'exec': function(callback, args){
      if(typeof callback === 'function'){
        callback.apply(null, args)
        //call() accepts an argument list, whereas
        //apply() accepts a single array of arguments.
      }
    }
  }

  self.error = {
    'log': function(){
      console.log.apply(console, arguments);
    }
  }

  // async/await future
  //self.loadData('https://localhost/dfgdf').then(data => console.log(data))
  self.loadData = async function(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      //const data = await response.json();
      //const data = await response.text();
      const data = await response.status;
      return data
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

})();

export const otherObj = new (function(){
  let self = this

  self.init = function(){
    self.emitter.emit('init', 'otherObj.init');
    self.listener.list()
  }

  self.emitter = {
    'events': {},
    'on': function(eventName, listener) {
      if (!self.emitter.events[eventName]) {
        self.emitter.events[eventName] = [];
      }
      self.emitter.events[eventName].push(listener);
    },
    'emit': function(eventName, ...args) {
      if(self.emitter.events[eventName]) {
        self.emitter.events[eventName].forEach(listener => {
          listener.apply(null, args);
        });
      }
    }
  }

  self.listener = {
    'list': function(){
      header_menu.emitter.on('init', function(data) {

      })
    }
  }

})();

otherObj.init()

export default header_menu;